@import "var.conf";
@import "base.css";
@import "common.css";
@import "page2.css";



























































/*---------------------------------
Page CSS 2018.01
CSS 担当者名 Document 2018.01
Last Update 2018.01 担当者名
---------------------------------*/

@media print,
screen and (min-width: 768px) {

  /*
  TOPページ・会社案内共通 PC
  -------------------------------------*/
  .introduce {
    margin-top: 91px;
    h3 {
      margin-bottom: 47px;
    }
    figure {
      float: right;
      width: 280px;
      text-align: center;
      margin-top: 6px;
      img {
        width: 280px;
        height: auto;
        margin-bottom: 19px;
      }
      span {
        font-size: 24px;
        color: #000;
        span:nth-of-type(1) {
          font-size: 14px;
          display: block;
          margin-bottom: 12px;
        }
      }
    }
    .divRight {
      float: left;
      width: 670px;
    }
  }

  /*
  ホーム PC
  -------------------------------------*/
  #Page.pageIndex #Main {
    float: none;
    width: auto;
    #ContBox01 {
      h3:before {
        content: "";
        display: inline-block;
        position: relative;
      }
      #ContSubBox01.introduce {
        h3 {
          &:before {
            top: 3px;
            left: 10px;
            width: 44px;
            height: 47px;
            background: url("/common/img/contents/index_icon01.png") no-repeat scroll left center;
            background-size: 44px auto;
          }
        }
        .divRight {
          float: right;
          width: 670px;
        }
        figure {
          float: left;
        }
        h3 {
          &:before {
            top: 3px;
            left: 10px;
            width: 44px;
            height: 47px;
            background: url("/common/img/contents/index_icon01.png") no-repeat scroll left center;
            background-size: 44px auto;
          }
        }
      }
      #ContSubBox02 {
        margin-top: 130px;
        h3 {
          margin-bottom: 52px;
          &:before {
            left: -21px;
            width: 46px;
            height: 51px;
            background: url("/common/img/contents/index_icon05.png") no-repeat scroll left center;
            background-size: 46px 46px;
            vertical-align: bottom;
          }
        }
        figure {
          img {
            width: 100%;
            height: auto;
          }
        }
        figure.graph01 {
          width: 730px;
          margin: 22px auto 57px;
        }
        figure.graph02 {
          width: 100%;
          margin: 31px auto 54px;
        }
        figure.graph03 {
          width: 100%;
          margin: 34px auto 51px;
        }
      }
      #ContSubBox03 {
        margin-top: 130px;
        h3 {
          margin-bottom: 52px;
          &:before {
            left: 10px;
            width: 47px;
            height: 40px;
            background: url("/common/img/contents/index_icon02.png") no-repeat scroll left center;
            background-size: 47px auto;
          }
        }
        p {
          text-align: center;
          span {
            display: block;
            font-size: 18px;
            color: #666;
          }
        }
        ul {
          margin-top: 32px;
          font-size: 0;
          li {
            display: inline-block;
            width: 490px;
            height: 80px;
            &:nth-of-type(1) {
              margin-right: 17px;
            }
            a {
              display: block;
              width: 100%;
              height: 100%;
              color: #fff;
              font-size: 30px;
              line-height: 80px;
              text-align: center;
              border-radius: 50px;
              background: #80ab50 url(../img/index_arrow02.png) no-repeat center right 28px;
              background-size: 17px auto;
            }
          }
        }
      }
      #ContSubBox04 {
        margin-top: 130px;
        padding-bottom: 130px;
        h3 {
          margin-bottom: 60px;
          &:before {
            top: 10px;
            left: 10px;
            width: 47px;
            height: 53px;
            background: url("/common/img/contents/index_icon03.png") no-repeat scroll left center;
            background-size: 47px auto;
          }
        }
        ol.kifu_flow {
          counter-reset: number;
          list-style: none;
          margin: 0;
          padding: 0;
          overflow: hidden;
          li {
            width: 100px;
            height: 458px;
            border-radius: 14px;
            float: left;
            border: 3px solid #e9633a;
            background: #fff;
            font-size: 26px;
            color: #e9633a;
            position: relative;
            font-weight: 700;
            span.kifu_flow_span {
              writing-mode: vertical-rl;
              position: absolute;
              top: 65px;
              left: 50%;
              display: inline;
              transform: translate(-50%, 0);
              &:after {
                position: relative;
                top: 13px;
                display: inline-block;
                font-size: 18px;
                font-weight: 700;
                width: 30px;
                height: 15px;
                writing-mode: horizontal-tb;
                -ms-writing-mode: lr-tb;
              }
            }
            &:nth-of-type(1) span.kifu_flow_span:after {
              content: "※1";
            }
            &:nth-of-type(2) span.kifu_flow_span:after {
              content: "※2";
            }
            &:nth-of-type(3) span.kifu_flow_span:after,
            &:nth-of-type(4) span.kifu_flow_span:after {
              display: none;
            }
            &:nth-of-type(5) span.kifu_flow_span:after {
              content: "※3";
            }
            &:nth-of-type(6) span.kifu_flow_span:after {
              content: "※4";
            }
            &:before {
              counter-increment: number;
              content: counter(number);
              display: block;
              position: absolute;
              top: 25px;
              left: 0;
              right: 0;
              margin: auto;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border-radius: 50%;
              background: #e9633a;
              font-size: 20px;
              font-weight: 600;
              color: #fff;
              font-family: "Open Sans", sans-serif;
            }
            &:after {
              content: "";
              width: 76px;
              height: 418px;
              display: inline-block;
              background: url(../img/contents/index_arrow01.png) no-repeat center left;
              background-size: 76px auto;
              position: absolute;
              top: 0;
              right: -79px;
            }
            &+li {
              margin-left: 80px;
            }
            &:last-of-type {
              &:after {
                display: none;
              }
            }
          }
        }
        ol.ol_note {
          margin-top: 39px;
          li {
            color: #666;
            font-size: 18px;
            line-height: 30px;
            list-style: none;
            padding-left: 1em;
            text-indent: -1.9em;
          }
        }
      }
      #ContSubBox05 {
        padding-top: 81px;
        padding-bottom: 81px;
        background-color: #fff;
        h3 {
          position: relative;
          width: 780px;
          margin: 0 auto;
          font-size: 36px;
          font-weight: 700;
          line-height: 34px;
          margin-bottom: 25px;
          letter-spacing: -2px;
          &:before {
            top: 3px;
            left: -5px;
            width: 36px;
            height: 31px;
            background: url("/common/img/contents/index_icon04.png") no-repeat scroll left center;
            background-size: 36px auto;
          }
          span.newsMore {
            position: absolute;
            right: 0;
            display: inline-block;
            color: #010101;
            font-size: 16px;
            vertical-align: middle;
            font-weight: 500;
            letter-spacing: 0;
            &:before {
              content: "";
              width: 8px;
              height: 34px;
              display: inline-block;
              background: url(../img/contents/arrow01.png) no-repeat center left;
              background-size: 8px auto;
              position: absolute;
              left: -18px;
            }
          }
        }
        dl {
          width: 780px;
          margin: 0 auto;
          display: table;
          background: url(../img/contents/line_img.png) repeat-x left top;
          background-size: 3px auto;
          &:last-child {
            background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
            background-repeat: repeat-x, repeat-x;
            background-position: left top, left bottom;
            background-size: 3px auto, 3px auto;
          }
          dt {
            display: table-cell;
            width: 125px;
            padding-top: 15px;
            padding-bottom: 14px;
            vertical-align: top;
            font-family: 'Open Sans', sans-serif;
            color: #666;
            font-size: 18px;
            line-height: 30px;
          }
          dd {
            padding-top: 16px;
            vertical-align: top;
            display: table-cell;
            padding-left: 20px;
            color: #010101;
            font-size: 18px;
            line-height: 30px;
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  /*
  遺贈寄付先について PC
  -------------------------------------*/
  #PageContributionlist.pageIndex #Main {
    width: 100%;
    #ContBox01 {
      margin-top: 82px;
      div.greeting p {
        text-align: center;
      }
      h3 {
        margin-bottom: 60px;
        &:before {
          display: inline-block;
          content: "";
          width: 34px;
          height: 47px;
          position: relative;
          top: 3px;
          margin-right: 15px;
        }
      }
      #ContSubBox01 {
        margin-top: 133px;
        h3 {
          &:before {
            display: inline-block;
            content: "";
            background: url("/common/img/contents/kifusaki_icon01.png") no-repeat scroll left center;
            background-size: 34px auto;
            position: relative;
            top: 3px;
            margin-right: 15px;
          }
        }
        .divLeft {
          float: left;
          width: 475px;
          margin-right: 50px;
          figure {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
        .divRight {
          float: left;
          width: 475px;
          p:nth-of-type(1) {
            color: #000;
            font-size: 26px;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 17px;
          }
          ul {
            margin-bottom: 22px;
            li {
              background: url("/common/img/contents/kifusaki_icon06.png") no-repeat scroll left 5px;
              background-size: 23px auto;
              padding-left: 36px;
              line-height: 36px;
              &+li {
                margin-top: 5px;
              }
            }
          }
        }
      }
      #ContSubBox02 {
        margin-top: 129px;
        margin-bottom: 203px;
        div.greeting {
          margin-bottom: 73px;
        }
        h3 {
          margin-bottom: 48px;
          &:before {
            content: "";
            display: inline-block;
            width: 57px;
            height: 47px;
            background: url("/common/img/contents/kifusaki_icon02.png") no-repeat scroll left center;
            background-size: 57px auto;
            position: relative;
            top: 3px;
            margin-right: 15px;
          }
        }
        h4 {
          position: relative;
          background-color: #714721;
          width: 1000px;
          margin: 0 auto;
          height: 70px;
          color: #fff;
          font-size: 26px;
          font-weight: 700;
          line-height: 70px;
          text-align: center;
          margin-bottom: 79px;
          margin-top: 153px;
          &:before {
            content: "";
            display: inline-block;
            position: relative;
          }
          &#contributionlist_01 {
            margin-top: 53px;
          }
          &#contributionlist_01:before {
            width: 32px;
            height: 36px;
            background: url("/common/img/contents/kifusaki_icon03.png") no-repeat scroll left center;
            background-size: 32px auto;
            top: 9px;
            margin-right: 18px;
          }
          &#contributionlist_02:before {
            width: 29px;
            height: 35px;
            background: url("/common/img/contents/kifusaki_icon04.png") no-repeat scroll left center;
            background-size: 29px auto;
            top: 8px;
            margin-right: 14px;
          }
          &#contributionlist_03:before {
            width: 39px;
            height: 31px;
            background: url("/common/img/contents/kifusaki_icon05.png") no-repeat scroll left center;
            background-size: 39px auto;
            top: 5px;
            margin-right: 14px;
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -12px;
            left: 50%;
            margin: 0 0 0 -5px;
            width: 0;
            height: 0;
            border-right: 15px solid transparent;
            border-top: 15px solid #714721;
            ;
            border-left: 15px solid transparent;
          }
        }
        nav.contributionlist_nav {
          border-bottom: 1px solid #ece6d7;
          padding-bottom: 29px;
          margin-bottom: 79px;
          ul {
            li {
              display: inline-block;
              color: #010101;
              font-size: 18px;
              border-right: 1px solid #dfd8c6;
              text-align: center;
              &:nth-of-type(1) {
                width: 338px;
                padding-left: 25px;
              }
              &:nth-of-type(2) {
                width: 193px;
              }
              &:nth-of-type(3) {
                width: 272px;
              }
              &:nth-of-type(4) {
                width: 188px;
                border: none;
              }
              a {
                &:before {
                  content: "";
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  background: url("/common/img/contents/arrow02.png") no-repeat scroll left center;
                  background-size: 8px auto;
                  margin-right: 8px;
                  position: relative;
                  top: -3px;
                }
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .accordionInner {
          margin-bottom: 112px;
          >div {
            width: 805px;
            margin: 0 auto;
            p,
            dl {
              font-size: 18px;
              line-height: 30px;
            }
            a {
              color: #80ab50;
            }
            div {
              margin-top: 21px;
              dl {
                margin-bottom: 20px;
                dt {
                  font-weight: 700;
                  span.orange {
                    color: #e9633a;
                  }
                }
                dd {
                  margin-left: 1em;
                }
              }
            }
          }
          p.another_link {
            text-align: center;
            margin: 38px 0 33px;
            font-size: 26px;
            font-weight: 700;
            span {
              display: block;
              font-size: 20px;
              line-height: 24px;
            }
            a {
              color: #80ab50;
              text-decoration: underline;
              &:before {
                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url("/common/img/contents/icon_window.png") no-repeat scroll left center;
                background-size: 22px auto;
                margin-right: 10px;
                position: relative;
                top: 3px;
              }
            }
          }
        }
      }
    }
  }

  /*
  トピックス共通 PC
  -------------------------------------*/
  #PageTopics {
    #Main {
      width: 700px;
      #ContBox01 {
        margin-top: 90px;
        margin-bottom: 200px;
        dl {
          width: 100%;
          padding: 20px 0;
          background: url(../img/contents/line_img.png) repeat-x left top;
          background-size: 3px auto;
          &:last-of-type {
            background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
            background-repeat: repeat-x, repeat-x;
            background-position: left top, left bottom;
            background-size: 3px auto, 3px auto;
          }
          dt {
            overflow: hidden;
            width: 630px;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 20px;
            line-height: 34px;
            a:hover {
              opacity: 1;
              text-decoration: underline;
            }
          }
          dd {
            display: inline-block;
            vertical-align: middle;
          }
          dd.date {
            width: 90px;
            padding-right: 15px;
            font-family: "Open Sans", sans-serif;
            color: #666;
            font-size: 18px;
            line-height: 34px;
          }
        }
        .category {
          display: inline-block;
          position: relative;
          top: 3px;
          border: 1px solid #e9633a;
          border-radius: 3px;
          background-color: #ffffff;
          height: 20px;
          color: #e9633a;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          margin-left: 18px;
          padding-left: 6px;
          padding-right: 6px;
          letter-spacing: -1px;
          &+.category {
            margin-left: 13px;
          }
        }
      }
    }
  }

  /*
  トピックス 見出し共通 PC
  -------------------------------------*/
  #PageTopics #Main {
    #ContBox01 {
      h3 {
        font-size: 36px;
        text-align: left;
        margin-bottom: 30px;
        line-height: 44px;
      }
    }
  }
  /*
  月別アーカイブ PC
  -------------------------------------*/
  #PageTopics.pageMonthly #Main {
    #ContBox01 {}
  }
  /*
  記事ページ PC
  -------------------------------------*/
  #PageTopics.pageEntry #Main {
    #ContBox01 {
      h3 {
        margin-top: 4px;
        margin-bottom: 19px;
      }
      span.date {
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        color: #666;
        line-height: 34px;
      }
      span.category {
        position: relative;
        top: -2px;
      }
      .entryBox {
        padding-top: 32px;
        padding-bottom: 74px;
        border-top: 3px solid #e2ded4;
        border-bottom: 1px solid #e2ded4;
      }
    }
  }

  /*
  会社案内 PC
  -------------------------------------*/
  #PageCompany.pageIndex #Main {
    #ContBox01 {
      #ContSubBox02 {
        margin-top: 140px;
        margin-bottom: 250px;
        h3 {
          margin-bottom: 60px;
        }
        .divLeft {
          float: left;
          width: 475px;
          margin-right: 50px;
          #GoogleMap {
            width: 100%;
            height: 500px;
            margin-bottom: 5px;
            border: 1px solid #d9d9d9;
          }
          p {
            text-align: right;
            a {
              color: #86c441;
              font-size: 16px;
              &:before {
                display: inline-block;
                content: "";
                width: 23px;
                height: 17px;
                background: url("/common/img/contents/kaisya_icon01.png") no-repeat scroll left center;
                background-size: 17px auto;
                position: relative;
                top: 3px;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .divRight {
          float: right;
          width: 470px;
          dl {
            padding: 14px 0 15px;
            font-size: 20px;
            line-height: 34px;
            overflow: hidden;
            background: url(../img/contents/line_img.png) repeat-x left top;
            background-size: 3px auto;
            &:last-child {
              background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
              background-repeat: repeat-x, repeat-x;
              background-position: left top, left bottom;
              background-size: 3px auto, 3px auto;
            }
            dt {
              float: left;
              width: 120px;
              font-weight: 700;
            }
            dd {
              float: left;
              width: 350px;
            }
          }
        }
      }
    }
  }
  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex #Main {
    #ContBox01 {
      #ContSubBox01 {}
      #ContSubBox02 {}
    }
  }
  /*
  お問い合わせ（確認）
  -------------------------------------*/
  #PageContact.pageConfirm #Main {
    #ContBox01 {
      #ContSubBox01 {}
      #ContSubBox02 {}
    }
  }
  /*
  お問い合わせ（完了）
  -------------------------------------*/
  #PageContact.pageThanks #Main {
    #ContBox01 {
      #ContSubBox01 {}
      #ContSubBox02 {}
    }
  }
  /*

  -------------------------------------*/
  #Main {
    #ContBox01 {
      #ContSubBox01 {}
      #ContSubBox02 {}
    }
  }
}

@media print,
screen and (max-width: 767px) {

  /*
  ホーム・会社案内共通 SP
  -------------------------------------*/
  .introduce {
    margin-top: 54px;
    h3 {
      margin-bottom: 39px;
    }
    figure {
      width: 210px;
      text-align: center;
      margin: 30px auto;
      img {
        width: 100%;
        height: auto;
        margin-bottom: 16px;
      }
      span {
        font-size: 22px;
        color: #000;
        span:nth-of-type(1) {
          font-size: 12px;
          display: block;
          margin-bottom: 8px;
        }
      }
    }
  }

  /*
  ホーム SP
  -------------------------------------*/
  #Page.pageIndex #Main {
    float: none;
    width: auto;
    #ContBox01 {
      padding: 0;
      h3:before {
        content: "";
        display: inline-block;
        position: relative;
      }
      .divContact {
        margin-top: 30px;
        text-align: center;
        ul {
          li {
            &:first-child {
              display: inline-block;
              background: url(../img/tel_icon.png) no-repeat left bottom 8px;
              background-size: 35px auto;
              padding-left: 40px;
              a {
                font-size: 39px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                color: #000000;
                text-decoration: underline;
                &:visited {
                  color: #000000;
                  text-decoration: underline;
                }
              }
            }
            &:nth-child(2) {
              width: 303px;
              margin: 7px auto 0;
              padding: 0;
              border: 1px solid #e9633a;
              border-radius: 5px;
              background: #fff;
              color: #e9633a;
              font-size: 13px;
              font-weight: 700;
              text-align: center;
              line-height: 21px;
              letter-spacing: .5px;
            }
          }
        }
      }
      .divEnding {
        margin: 22px auto 0;
        max-width: 345px;
        height: 90px;
        border: 6px solid #f6f1e5;
        background: #fff;
        p {
          display: table-cell;
          height: 78px;
          vertical-align: middle;
          color: #e9633a;
          font-size: 15px;
          line-height: 20px;
          padding-left: 135px;
          background: url(../img/contents/mainimg_icon01_sp.png) no-repeat center left 15px;
          background-size: 109px auto;
          font-weight: 700;
          span {
            display: block;
            letter-spacing: -1.5px;
          }
        }
      }
      #ContSubBox01.introduce {
        padding-left: 15px;
        padding-right: 15px;
        h3 {
          &:before {
            top: 3px;
            left: 10px;
            width: 33px;
            height: 35px;
            background: url("/common/img/contents/index_icon01.png") no-repeat scroll left center;
            background-size: 33px auto;
          }
        }
      }
      #ContSubBox02 {
        margin-top: 105px;
        h3 {
          margin-bottom: 41px;
          padding-left:1em;
text-indent:-1em;
          &:before {
            left: -21px;
            width: 35px;
            height: 35px;
            background: url("/common/img/contents/index_icon05.png") no-repeat scroll left center;
            background-size: 35px 35px;
            vertical-align: bottom;
          }
        }
        p {
          padding-left: 15px;
          padding-right: 15px;
          text-align:justify;
          &.dispsp.p_note {
            color: #666;
            font-size: 16px;
            line-height: 29px;
            letter-spacing:-0.4px;
                margin-bottom: 26px;
                text-align:center;
          }
        }
        figure {
                padding-left: 15px;
          padding-right: 15px;
          img {
            width: 100%;
            height: auto;
          }
        }
        figure.graph01 {
          width: 100%;
          margin: 22px auto 8px;
        }
        figure.graph02 {
          width: 100%;
          margin: 23px auto 8px;
        }
        figure.graph03 {
          width: 100%;
          margin: 23px auto 9px;
        }
      }
      #ContSubBox03 {
        margin-top: 89px;
        padding-left: 15px;
        padding-right: 15px;
        h3 {
          margin-bottom: 40px;
          line-height: 40px;
          &:before {
            left: 10px;
            width: 35px;
            height: 30px;
            background: url("/common/img/contents/index_icon02.png") no-repeat scroll left center;
            background-size: 35px auto;
          }
        }
        p {
          span {
            display: block;
            font-size: 16px;
            color: #666;
          }
        }
        ul {
          margin-top: 24px;
          li {
            display: block;
            width: 100%;
            height: 60px;
            &:nth-of-type(1) {
              margin-bottom: 15px;
            }
            a {
              display: block;
              width: 100%;
              height: 100%;
              color: #fff;
              font-size: 22px;
              line-height: 60px;
              text-align: center;
              border-radius: 34px;
              background: #80ab50 url(../img/index_arrow02.png) no-repeat center right 28px;
              background-size: 13px auto;
            }
          }
        }
      }
      #ContSubBox04 {
        margin-top: 78px;
        padding: 0 15px 88px;
        h3 {
          margin-bottom: 50px;
          &:before {
            top: 10px;
            left: 10px;
            width: 47px;
            height: 53px;
            background: url("/common/img/contents/index_icon03.png") no-repeat scroll left center;
            background-size: 47px auto;
          }
        }
        ol.kifu_flow {
          counter-reset: number;
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            width: 100%;
            border-radius: 8px;
            border: 3px solid #e9633a;
            background: #fff;
            font-size: 24px;
            color: #e9633a;
            position: relative;
            font-weight: 700;
            line-height: 33px;
            padding: 10px 9px 10px 44px;
            span.kifu_flow_span {
              &:after {
                position: relative;
                top: -3px;
                display: inline-block;
                width: 30px;
                height: 0px;
                font-size: 16px;
                margin-left: 12px;
                font-weight: 700;
              }
            }
            &:nth-of-type(1) span.kifu_flow_span:after {
              content: "※1";
            }
            &:nth-of-type(2) span.kifu_flow_span:after {
              content: "※2";
            }
            &:nth-of-type(3) span.kifu_flow_span:after,
            &:nth-of-type(4) span.kifu_flow_span:after {
              display: none;
            }
            &:nth-of-type(5) span.kifu_flow_span:after {
              content: "※3";
            }
            &:nth-of-type(6) span.kifu_flow_span:after {
              content: "※4";
            }
            &:before {
              counter-increment: number;
              content: counter(number);
              display: inline-block;
              position: absolute;
              top: 15px;
              left: 11px;
              width: 23px;
              height: 23px;
              line-height: 23px;
              text-align: center;
              border-radius: 50%;
              background: #e9633a;
              font-size: 15px;
              font-weight: 600;
              color: #fff;
              font-family: "Open Sans", sans-serif;
              text-indent: 1px;
            }
            &:after {
              content: "";
              width: 100%;
              height: 27px;
              display: inline-block;
              background: url(../img/contents/index_arrow01_sp.png) no-repeat center center;
              background-size: 27px auto;
              position: absolute;
              bottom: -29px;
              left: 0;
            }
            &+li {
              margin-top: 29px;
            }
            &:last-of-type {
              &:after {
                display: none;
              }
            }
          }
        }
        ol.ol_note {
          margin-top: 30px;
          padding-left: 15px;
          li {
            color: #666;
            font-size: 16px;
            line-height: 27px;
            list-style: none;
            padding-left: 1em;
            text-indent: -1.9em;
          }
        }
      }
      #ContSubBox05 {
        padding: 93px 15px 58px;
        background-color: #fff;
        h3 {
          position: relative;
          width: 100%;
          margin: 0 auto;
          font-size: 24px;
          font-weight: 700;
          line-height: 34px;
          margin-bottom: 19px;
          letter-spacing: -2px;
          &:before {
            top: 3px;
            left: -5px;
            width: 27px;
            height: 23px;
            background: url("/common/img/contents/index_icon04.png") no-repeat scroll left center;
            background-size: 27px auto;
          }
          span.newsMore {
            display: none;
          }
        }
        p {
          text-align: center;
          span.newsMore {
            position: relative;
            display: inline-block;
            color: #010101;
            font-size: 14px;
            letter-spacing: 0;
            margin-top: 23px;
            &:before {
              content: "";
              width: 8px;
              height: 34px;
              display: inline-block;
              background: url(../img/contents/arrow01.png) no-repeat center left;
              background-size: 8px auto;
              position: absolute;
              top: -3px;
              left: -15px;
            }
          }
        }
        dl {
          width: 100%;
          margin: 0 auto;
          display: table;
          padding-top: 13px;
          padding-bottom: 11px;
          background: url(../img/contents/line_img.png) repeat-x left top;
          background-size: 3px auto;
          &:last-child {
            background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
            background-repeat: repeat-x, repeat-x;
            background-position: left top, left bottom;
            background-size: 3px auto, 3px auto;
          }
          dt {
            display: block;
            width: 100%;
            padding-bottom: 4px;
            vertical-align: top;
            font-family: 'Open Sans', sans-serif;
            color: #666;
            font-size: 16px;
            line-height: 30px;
          }
          dd {
            display: block;
            color: #010101;
            font-size: 16px;
            line-height: 27px;
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  /*
  遺贈寄付先について SP
  -------------------------------------*/
  #PageContributionlist.pageIndex #Main {
    width: 100%;
    #ContBox01 {
      margin-top: 48px;
      padding: 0;
      div.greeting p {
        padding-left: 15px;
        padding-right: 15px;
        &.orange {
          color: #e9633a;
        }
      }
      h3 {
        margin-bottom: 60px;
        &:before {
          display: inline-block;
          content: "";
          width: 34px;
          height: 47px;
          position: relative;
          top: 3px;
          margin-right: 15px;
        }
      }
      #ContSubBox01 {
        margin-top: 80px;
        padding-left: 15px;
        padding-right: 15px;
        h3 {
          margin-bottom: 47px;
          &:before {
            display: inline-block;
            content: "";
            background: url("/common/img/contents/kifusaki_icon01.png") no-repeat scroll left center;
            background-size: 25px auto;
            position: relative;
            top: 9px;
            margin-right: 5px;
          }
        }
        .divLeft {
          width: 100%;
          margin-bottom: 18px;
          figure {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
        .divRight {
          width: 100%;
          p:nth-of-type(1) {
            color: #000;
            font-size: 24px;
            font-weight: 700;
            line-height: 35px;
            margin-bottom: 8px;
          }
          ul {
            margin-bottom: 8px;
            li {
              background: url("/common/img/contents/kifusaki_icon06.png") no-repeat scroll left 1px;
              background-size: 23px auto;
              padding-left: 36px;
              &+li {
                margin-top: 5px;
              }
            }
          }
        }
      }
      #ContSubBox02 {
        margin-top: 89px;
        div.greeting {
          margin-bottom: 22px;
        }
        h3 {
          margin-bottom: 42px;
          &:before {
            content: "";
            display: inline-block;
            width: 42px;
            height: 34px;
            background: url("/common/img/contents/kifusaki_icon02.png") no-repeat scroll left center;
            background-size: 42px auto;
            position: relative;
            top: 3px;
            margin-right: 15px;
          }
        }
        .parentAccordion.minus h4 {
          border-bottom: 1px solid #e7e7e7;
          background: #fff url("/common/img/contents/sp_icon_open.png") no-repeat scroll right 15px center;
          background-size: 23px auto;
        }
        div.accordion {
          display: none;
        }
        h4 {
          color: #714721;
          background-color: #fff;
          width: 100%;
          height: 65px;
          font-size: 24px;
          font-weight: 700;
          line-height: 65px;
          border-top: 1px solid #e7e7e7;
          background: #fff url("/common/img/contents/sp_icon_close.png") no-repeat scroll right 15px center;
          background-size: 23px auto;
          &:before {
            content: "";
            display: inline-block;
            position: relative;
            left: 15px;
            top: 3px;
            margin-right: 26px;
          }
          &#contributionlist_01:before {
            width: 24px;
            height: 27px;
            background: url("/common/img/contents/kifusaki_icon03_sp.png") no-repeat scroll left center;
            background-size: 24px auto;
          }
          &#contributionlist_02:before {
            width: 23px;
            height: 27px;
            background: url("/common/img/contents/kifusaki_icon04_sp.png") no-repeat scroll left center;
            background-size: 23px auto;
          }
          &#contributionlist_03:before {
            width: 29px;
            height: 23px;
            background: url("/common/img/contents/kifusaki_icon05_sp.png") no-repeat scroll left center;
            background-size: 29px auto;
            top: 5px;
            margin-right: 20px;
          }
        }
        .accordionInner {
          margin-top: 39px;
          margin-bottom: 60px;
          padding-left: 15px;
          padding-right: 15px;
          figure {
            img {
              width: 100%;
            }
          }
          >div {
            width: 100%;
            p,
            dl {
              font-size: 16px;
              line-height: 27px;
            }
            a {
              color: #80ab50;
            }
            div {
              margin-top: 8px;
              dl {
                margin-bottom: 8px;
                dt {
                  font-weight: 700;
                  span.orange {
                    color: #e9633a;
                  }
                }
                dd {
                  margin-left: 1em;
                }
              }
            }
          }
          p.another_link {
            margin: 20px 0 15px;
            font-size: 24px;
            line-height: 35px;
            font-weight: 700;
            padding-left: 1.2em;
            background: url("/common/img/contents/icon_window.png") no-repeat scroll left 5px;
            background-size: 22px auto;
            span {
              display: block;
              font-size: 18px;
              line-height: 28px;
            }
            a {
              color: #80ab50;
              text-decoration: underline;
            }
          }
        }
        #contributionlist_04 {
          border-bottom: 1px solid #e7e7e7;
          padding-left: 15px;
          &:before {
            display: none;
          }
          &+.accordion {
            p.another_link {
              background: none;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  /*
  トピックス共通 SP
  -------------------------------------*/
  #PageTopics {
    #Main {
      width: 100%;
      #ContBox01 {
        margin-top: 53px;
        margin-bottom: 100px;
        dl {
          width: 100%;
          padding: 18px 0;
          background: url(../img/contents/line_img.png) repeat-x left top;
          background-size: 3px auto;
          &:last-of-type {
            background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
            background-repeat: repeat-x, repeat-x;
            background-position: left top, left bottom;
            background-size: 3px auto, 3px auto;
          }
          dt {
            overflow: hidden;
            width: 100%; // white-space: nowrap;
            // text-overflow: ellipsis;
            font-size: 18px;
            line-height: 29px;
            a:link,
            a:active,
            a:visited {
              color: #010101;
            }
          }
          dd {
            display: inline-block;
            vertical-align: middle;
          }
          dd.date {
            width: 90px;
            padding-right: 15px;
            font-family: "Open Sans", sans-serif;
            color: #666;
            font-size: 16px;
            line-height: 27px;
          }
        }
        .category {
          height: 20px;
          margin-left: 3px;
          padding-right: 8px;
          padding-left: 6px;
          border: 1px solid #e9633a;
          border-radius: 3px;
          background-color: #fff;
          color: #e9633a;
          font-size: 12px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -1px;
        }
      }
    }
  }
  /*
  トピックス 見出し共通 SP
  -------------------------------------*/
  #PageTopics #Main {
    #ContBox01 {
      h3 {
        font-size: 34px;
        text-align: left;
        margin-bottom: 21px;
        line-height: 40px;
      }
    }
  }
  /*
  月別アーカイブ SP
  -------------------------------------*/
  #PageTopics.pageMonthly #Main {
    #ContBox01 {
      h3 {
        letter-spacing: 2px;
      }
    }
  }
  /*
  記事ページ SP
  -------------------------------------*/
  #PageTopics.pageEntry #Main {
    #ContBox01 {
      h3 {
        margin-top: 4px;
        margin-bottom: 19px;
      }
      span.date {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        color: #666;
        line-height: 34px;
      }
      span.category {
        position: relative;
        top: -2px;
        left: 12px;
      }
      .entryBox {
        padding-top: 26px;
        padding-bottom: 59px;
        border-top: 3px solid #e2ded4;
        border-bottom: 1px solid #e2ded4;
      }
    }
  }
  /*
  会社案内 SP
  -------------------------------------*/
  #PageCompany.pageIndex #Main {
    #ContBox01 {
      #ContSubBox02 {
        margin-top: 96px;
        margin-bottom: 80px;
        h3 {
          margin-bottom: 48px;
        }
        .divLeft {
          width: 100%;
          #GoogleMap {
            width: 100%;
            height: 360px;
            margin-bottom: 5px;
            border: 1px solid #d9d9d9;
          }
          p {
            text-align: right;
            a {
              color: #86c441;
              font-size: 14px;
              &:before {
                display: inline-block;
                content: "";
                width: 23px;
                height: 17px;
                background: url("/common/img/contents/kaisya_icon01.png") no-repeat scroll left center;
                background-size: 17px auto;
                position: relative;
                top: 3px;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .divRight {
          width: 100%;
          margin-top: 32px;
          dl {
            padding: 14px 0 15px;
            font-size: 18px;
            line-height: 29px;
            overflow: hidden;
            background: url(../img/contents/line_img.png) repeat-x left top;
            background-size: 3px auto;
            &:last-of-type {
              background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
              background-repeat: repeat-x, repeat-x;
              background-position: left top, left bottom;
              background-size: 3px auto, 3px auto;
            }
            dt {
              float: left;
              width: 34%;
              font-weight: 700;
            }
            dd {
              float: left;
              width: 66%;
              a {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}