@charset "UTF-8";
@import url(base.css);
@import url(common.css);
@import url(page2.css);
/*---------------------------------
Page CSS 2018.01
CSS 担当者名 Document 2018.01
Last Update 2018.01 担当者名
---------------------------------*/
@media print, screen and (min-width: 768px) {
  /*
  TOPページ・会社案内共通 PC
  -------------------------------------*/
  .introduce {
    margin-top: 91px; }
    .introduce h3 {
      margin-bottom: 47px; }
    .introduce figure {
      float: right;
      width: 280px;
      text-align: center;
      margin-top: 6px; }
      .introduce figure img {
        width: 280px;
        height: auto;
        margin-bottom: 19px; }
      .introduce figure span {
        font-size: 24px;
        color: #000; }
        .introduce figure span span:nth-of-type(1) {
          font-size: 14px;
          display: block;
          margin-bottom: 12px; }
    .introduce .divRight {
      float: left;
      width: 670px; }
  /*
  ホーム PC
  -------------------------------------*/
  #Page.pageIndex #Main {
    float: none;
    width: auto; }
    #Page.pageIndex #Main #ContBox01 h3:before {
      content: "";
      display: inline-block;
      position: relative; }
    #Page.pageIndex #Main #ContBox01 #ContSubBox01.introduce h3:before {
      top: 3px;
      left: 10px;
      width: 44px;
      height: 47px;
      background: url("/common/img/contents/index_icon01.png") no-repeat scroll left center;
      background-size: 44px auto; }
    #Page.pageIndex #Main #ContBox01 #ContSubBox01.introduce .divRight {
      float: right;
      width: 670px; }
    #Page.pageIndex #Main #ContBox01 #ContSubBox01.introduce figure {
      float: left; }
    #Page.pageIndex #Main #ContBox01 #ContSubBox01.introduce h3:before {
      top: 3px;
      left: 10px;
      width: 44px;
      height: 47px;
      background: url("/common/img/contents/index_icon01.png") no-repeat scroll left center;
      background-size: 44px auto; }
    #Page.pageIndex #Main #ContBox01 #ContSubBox02 {
      margin-top: 130px; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox02 h3 {
        margin-bottom: 52px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox02 h3:before {
          left: -21px;
          width: 46px;
          height: 51px;
          background: url("/common/img/contents/index_icon05.png") no-repeat scroll left center;
          background-size: 46px 46px;
          vertical-align: bottom; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure img {
        width: 100%;
        height: auto; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure.graph01 {
        width: 730px;
        margin: 22px auto 57px; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure.graph02 {
        width: 100%;
        margin: 31px auto 54px; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure.graph03 {
        width: 100%;
        margin: 34px auto 51px; }
    #Page.pageIndex #Main #ContBox01 #ContSubBox03 {
      margin-top: 130px; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox03 h3 {
        margin-bottom: 52px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox03 h3:before {
          left: 10px;
          width: 47px;
          height: 40px;
          background: url("/common/img/contents/index_icon02.png") no-repeat scroll left center;
          background-size: 47px auto; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox03 p {
        text-align: center; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox03 p span {
          display: block;
          font-size: 18px;
          color: #666; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox03 ul {
        margin-top: 32px;
        font-size: 0; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox03 ul li {
          display: inline-block;
          width: 490px;
          height: 80px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox03 ul li:nth-of-type(1) {
            margin-right: 17px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox03 ul li a {
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
            font-size: 30px;
            line-height: 80px;
            text-align: center;
            border-radius: 50px;
            background: #80ab50 url(../img/index_arrow02.png) no-repeat center right 28px;
            background-size: 17px auto; }
    #Page.pageIndex #Main #ContBox01 #ContSubBox04 {
      margin-top: 130px;
      padding-bottom: 130px; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox04 h3 {
        margin-bottom: 60px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox04 h3:before {
          top: 10px;
          left: 10px;
          width: 47px;
          height: 53px;
          background: url("/common/img/contents/index_icon03.png") no-repeat scroll left center;
          background-size: 47px auto; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow {
        counter-reset: number;
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li {
          width: 100px;
          height: 458px;
          border-radius: 14px;
          float: left;
          border: 3px solid #e9633a;
          background: #fff;
          font-size: 26px;
          color: #e9633a;
          position: relative;
          font-weight: 700; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li span.kifu_flow_span {
            writing-mode: vertical-rl;
            position: absolute;
            top: 65px;
            left: 50%;
            display: inline;
            transform: translate(-50%, 0); }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li span.kifu_flow_span:after {
              position: relative;
              top: 13px;
              display: inline-block;
              font-size: 18px;
              font-weight: 700;
              width: 30px;
              height: 15px;
              writing-mode: horizontal-tb;
              -ms-writing-mode: lr-tb; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(1) span.kifu_flow_span:after {
            content: "※1"; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(2) span.kifu_flow_span:after {
            content: "※2"; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(3) span.kifu_flow_span:after,
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(4) span.kifu_flow_span:after {
            display: none; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(5) span.kifu_flow_span:after {
            content: "※3"; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(6) span.kifu_flow_span:after {
            content: "※4"; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:before {
            counter-increment: number;
            content: counter(number);
            display: block;
            position: absolute;
            top: 25px;
            left: 0;
            right: 0;
            margin: auto;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            background: #e9633a;
            font-size: 20px;
            font-weight: 600;
            color: #fff;
            font-family: "Open Sans", sans-serif; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:after {
            content: "";
            width: 76px;
            height: 418px;
            display: inline-block;
            background: url(../img/contents/index_arrow01.png) no-repeat center left;
            background-size: 76px auto;
            position: absolute;
            top: 0;
            right: -79px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li + li {
            margin-left: 80px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:last-of-type:after {
            display: none; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.ol_note {
        margin-top: 39px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.ol_note li {
          color: #666;
          font-size: 18px;
          line-height: 30px;
          list-style: none;
          padding-left: 1em;
          text-indent: -1.9em; }
    #Page.pageIndex #Main #ContBox01 #ContSubBox05 {
      padding-top: 81px;
      padding-bottom: 81px;
      background-color: #fff; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox05 h3 {
        position: relative;
        width: 780px;
        margin: 0 auto;
        font-size: 36px;
        font-weight: 700;
        line-height: 34px;
        margin-bottom: 25px;
        letter-spacing: -2px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox05 h3:before {
          top: 3px;
          left: -5px;
          width: 36px;
          height: 31px;
          background: url("/common/img/contents/index_icon04.png") no-repeat scroll left center;
          background-size: 36px auto; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox05 h3 span.newsMore {
          position: absolute;
          right: 0;
          display: inline-block;
          color: #010101;
          font-size: 16px;
          vertical-align: middle;
          font-weight: 500;
          letter-spacing: 0; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox05 h3 span.newsMore:before {
            content: "";
            width: 8px;
            height: 34px;
            display: inline-block;
            background: url(../img/contents/arrow01.png) no-repeat center left;
            background-size: 8px auto;
            position: absolute;
            left: -18px; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl {
        width: 780px;
        margin: 0 auto;
        display: table;
        background: url(../img/contents/line_img.png) repeat-x left top;
        background-size: 3px auto; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl:last-child {
          background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
          background-repeat: repeat-x, repeat-x;
          background-position: left top, left bottom;
          background-size: 3px auto, 3px auto; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl dt {
          display: table-cell;
          width: 125px;
          padding-top: 15px;
          padding-bottom: 14px;
          vertical-align: top;
          font-family: 'Open Sans', sans-serif;
          color: #666;
          font-size: 18px;
          line-height: 30px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl dd {
          padding-top: 16px;
          vertical-align: top;
          display: table-cell;
          padding-left: 20px;
          color: #010101;
          font-size: 18px;
          line-height: 30px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl dd a:hover {
            text-decoration: underline; }
  /*
  遺贈寄付先について PC
  -------------------------------------*/
  #PageContributionlist.pageIndex #Main {
    width: 100%; }
    #PageContributionlist.pageIndex #Main #ContBox01 {
      margin-top: 82px; }
      #PageContributionlist.pageIndex #Main #ContBox01 div.greeting p {
        text-align: center; }
      #PageContributionlist.pageIndex #Main #ContBox01 h3 {
        margin-bottom: 60px; }
        #PageContributionlist.pageIndex #Main #ContBox01 h3:before {
          display: inline-block;
          content: "";
          width: 34px;
          height: 47px;
          position: relative;
          top: 3px;
          margin-right: 15px; }
      #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 {
        margin-top: 133px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 h3:before {
          display: inline-block;
          content: "";
          background: url("/common/img/contents/kifusaki_icon01.png") no-repeat scroll left center;
          background-size: 34px auto;
          position: relative;
          top: 3px;
          margin-right: 15px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divLeft {
          float: left;
          width: 475px;
          margin-right: 50px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divLeft figure {
            width: 100%; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divLeft figure img {
              width: 100%; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight {
          float: left;
          width: 475px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight p:nth-of-type(1) {
            color: #000;
            font-size: 26px;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 17px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight ul {
            margin-bottom: 22px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight ul li {
              background: url("/common/img/contents/kifusaki_icon06.png") no-repeat scroll left 5px;
              background-size: 23px auto;
              padding-left: 36px;
              line-height: 36px; }
              #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight ul li + li {
                margin-top: 5px; }
      #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 {
        margin-top: 129px;
        margin-bottom: 203px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 div.greeting {
          margin-bottom: 73px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h3 {
          margin-bottom: 48px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h3:before {
            content: "";
            display: inline-block;
            width: 57px;
            height: 47px;
            background: url("/common/img/contents/kifusaki_icon02.png") no-repeat scroll left center;
            background-size: 57px auto;
            position: relative;
            top: 3px;
            margin-right: 15px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4 {
          position: relative;
          background-color: #714721;
          width: 1000px;
          margin: 0 auto;
          height: 70px;
          color: #fff;
          font-size: 26px;
          font-weight: 700;
          line-height: 70px;
          text-align: center;
          margin-bottom: 79px;
          margin-top: 153px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4:before {
            content: "";
            display: inline-block;
            position: relative; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4#contributionlist_01 {
            margin-top: 53px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4#contributionlist_01:before {
            width: 32px;
            height: 36px;
            background: url("/common/img/contents/kifusaki_icon03.png") no-repeat scroll left center;
            background-size: 32px auto;
            top: 9px;
            margin-right: 18px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4#contributionlist_02:before {
            width: 29px;
            height: 35px;
            background: url("/common/img/contents/kifusaki_icon04.png") no-repeat scroll left center;
            background-size: 29px auto;
            top: 8px;
            margin-right: 14px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4#contributionlist_03:before {
            width: 39px;
            height: 31px;
            background: url("/common/img/contents/kifusaki_icon05.png") no-repeat scroll left center;
            background-size: 39px auto;
            top: 5px;
            margin-right: 14px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -12px;
            left: 50%;
            margin: 0 0 0 -5px;
            width: 0;
            height: 0;
            border-right: 15px solid transparent;
            border-top: 15px solid #714721;
            border-left: 15px solid transparent; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 nav.contributionlist_nav {
          border-bottom: 1px solid #ece6d7;
          padding-bottom: 29px;
          margin-bottom: 79px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 nav.contributionlist_nav ul li {
            display: inline-block;
            color: #010101;
            font-size: 18px;
            border-right: 1px solid #dfd8c6;
            text-align: center; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 nav.contributionlist_nav ul li:nth-of-type(1) {
              width: 338px;
              padding-left: 25px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 nav.contributionlist_nav ul li:nth-of-type(2) {
              width: 193px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 nav.contributionlist_nav ul li:nth-of-type(3) {
              width: 272px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 nav.contributionlist_nav ul li:nth-of-type(4) {
              width: 188px;
              border: none; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 nav.contributionlist_nav ul li a:before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 8px;
              background: url("/common/img/contents/arrow02.png") no-repeat scroll left center;
              background-size: 8px auto;
              margin-right: 8px;
              position: relative;
              top: -3px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 nav.contributionlist_nav ul li a:hover {
              text-decoration: underline; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner {
          margin-bottom: 112px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div {
            width: 805px;
            margin: 0 auto; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div p,
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div dl {
              font-size: 18px;
              line-height: 30px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div a {
              color: #80ab50; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div {
              margin-top: 21px; }
              #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div dl {
                margin-bottom: 20px; }
                #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div dl dt {
                  font-weight: 700; }
                  #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div dl dt span.orange {
                    color: #e9633a; }
                #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div dl dd {
                  margin-left: 1em; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner p.another_link {
            text-align: center;
            margin: 38px 0 33px;
            font-size: 26px;
            font-weight: 700; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner p.another_link span {
              display: block;
              font-size: 20px;
              line-height: 24px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner p.another_link a {
              color: #80ab50;
              text-decoration: underline; }
              #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner p.another_link a:before {
                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url("/common/img/contents/icon_window.png") no-repeat scroll left center;
                background-size: 22px auto;
                margin-right: 10px;
                position: relative;
                top: 3px; }
  /*
  トピックス共通 PC
  -------------------------------------*/
  #PageTopics #Main {
    width: 700px; }
    #PageTopics #Main #ContBox01 {
      margin-top: 90px;
      margin-bottom: 200px; }
      #PageTopics #Main #ContBox01 dl {
        width: 100%;
        padding: 20px 0;
        background: url(../img/contents/line_img.png) repeat-x left top;
        background-size: 3px auto; }
        #PageTopics #Main #ContBox01 dl:last-of-type {
          background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
          background-repeat: repeat-x, repeat-x;
          background-position: left top, left bottom;
          background-size: 3px auto, 3px auto; }
        #PageTopics #Main #ContBox01 dl dt {
          overflow: hidden;
          width: 630px;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 20px;
          line-height: 34px; }
          #PageTopics #Main #ContBox01 dl dt a:hover {
            opacity: 1;
            text-decoration: underline; }
        #PageTopics #Main #ContBox01 dl dd {
          display: inline-block;
          vertical-align: middle; }
        #PageTopics #Main #ContBox01 dl dd.date {
          width: 90px;
          padding-right: 15px;
          font-family: "Open Sans", sans-serif;
          color: #666;
          font-size: 18px;
          line-height: 34px; }
      #PageTopics #Main #ContBox01 .category {
        display: inline-block;
        position: relative;
        top: 3px;
        border: 1px solid #e9633a;
        border-radius: 3px;
        background-color: #ffffff;
        height: 20px;
        color: #e9633a;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        margin-left: 18px;
        padding-left: 6px;
        padding-right: 6px;
        letter-spacing: -1px; }
        #PageTopics #Main #ContBox01 .category + .category {
          margin-left: 13px; }
  /*
  トピックス 見出し共通 PC
  -------------------------------------*/
  #PageTopics #Main #ContBox01 h3 {
    font-size: 36px;
    text-align: left;
    margin-bottom: 30px;
    line-height: 44px; }
  /*
  月別アーカイブ PC
  -------------------------------------*/
  /*
  記事ページ PC
  -------------------------------------*/
  #PageTopics.pageEntry #Main #ContBox01 h3 {
    margin-top: 4px;
    margin-bottom: 19px; }
  #PageTopics.pageEntry #Main #ContBox01 span.date {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: #666;
    line-height: 34px; }
  #PageTopics.pageEntry #Main #ContBox01 span.category {
    position: relative;
    top: -2px; }
  #PageTopics.pageEntry #Main #ContBox01 .entryBox {
    padding-top: 32px;
    padding-bottom: 74px;
    border-top: 3px solid #e2ded4;
    border-bottom: 1px solid #e2ded4; }
  /*
  会社案内 PC
  -------------------------------------*/
  #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 {
    margin-top: 140px;
    margin-bottom: 250px; }
    #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 h3 {
      margin-bottom: 60px; }
    #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft {
      float: left;
      width: 475px;
      margin-right: 50px; }
      #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft #GoogleMap {
        width: 100%;
        height: 500px;
        margin-bottom: 5px;
        border: 1px solid #d9d9d9; }
      #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft p {
        text-align: right; }
        #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft p a {
          color: #86c441;
          font-size: 16px; }
          #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft p a:before {
            display: inline-block;
            content: "";
            width: 23px;
            height: 17px;
            background: url("/common/img/contents/kaisya_icon01.png") no-repeat scroll left center;
            background-size: 17px auto;
            position: relative;
            top: 3px; }
          #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft p a:hover {
            text-decoration: underline; }
    #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight {
      float: right;
      width: 470px; }
      #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl {
        padding: 14px 0 15px;
        font-size: 20px;
        line-height: 34px;
        overflow: hidden;
        background: url(../img/contents/line_img.png) repeat-x left top;
        background-size: 3px auto; }
        #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl:last-child {
          background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
          background-repeat: repeat-x, repeat-x;
          background-position: left top, left bottom;
          background-size: 3px auto, 3px auto; }
        #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl dt {
          float: left;
          width: 120px;
          font-weight: 700; }
        #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl dd {
          float: left;
          width: 350px; }
  /*
  お問い合わせ
  -------------------------------------*/
  /*
  お問い合わせ（確認）
  -------------------------------------*/
  /*
  お問い合わせ（完了）
  -------------------------------------*/
  /*

  -------------------------------------*/ }

@media print, screen and (max-width: 767px) {
  /*
  ホーム・会社案内共通 SP
  -------------------------------------*/
  .introduce {
    margin-top: 54px; }
    .introduce h3 {
      margin-bottom: 39px; }
    .introduce figure {
      width: 210px;
      text-align: center;
      margin: 30px auto; }
      .introduce figure img {
        width: 100%;
        height: auto;
        margin-bottom: 16px; }
      .introduce figure span {
        font-size: 22px;
        color: #000; }
        .introduce figure span span:nth-of-type(1) {
          font-size: 12px;
          display: block;
          margin-bottom: 8px; }
  /*
  ホーム SP
  -------------------------------------*/
  #Page.pageIndex #Main {
    float: none;
    width: auto; }
    #Page.pageIndex #Main #ContBox01 {
      padding: 0; }
      #Page.pageIndex #Main #ContBox01 h3:before {
        content: "";
        display: inline-block;
        position: relative; }
      #Page.pageIndex #Main #ContBox01 .divContact {
        margin-top: 30px;
        text-align: center; }
        #Page.pageIndex #Main #ContBox01 .divContact ul li:first-child {
          display: inline-block;
          background: url(../img/tel_icon.png) no-repeat left bottom 8px;
          background-size: 35px auto;
          padding-left: 40px; }
          #Page.pageIndex #Main #ContBox01 .divContact ul li:first-child a {
            font-size: 39px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: #000000;
            text-decoration: underline; }
            #Page.pageIndex #Main #ContBox01 .divContact ul li:first-child a:visited {
              color: #000000;
              text-decoration: underline; }
        #Page.pageIndex #Main #ContBox01 .divContact ul li:nth-child(2) {
          width: 303px;
          margin: 7px auto 0;
          padding: 0;
          border: 1px solid #e9633a;
          border-radius: 5px;
          background: #fff;
          color: #e9633a;
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          line-height: 21px;
          letter-spacing: .5px; }
      #Page.pageIndex #Main #ContBox01 .divEnding {
        margin: 22px auto 0;
        max-width: 345px;
        height: 90px;
        border: 6px solid #f6f1e5;
        background: #fff; }
        #Page.pageIndex #Main #ContBox01 .divEnding p {
          display: table-cell;
          height: 78px;
          vertical-align: middle;
          color: #e9633a;
          font-size: 15px;
          line-height: 20px;
          padding-left: 135px;
          background: url(../img/contents/mainimg_icon01_sp.png) no-repeat center left 15px;
          background-size: 109px auto;
          font-weight: 700; }
          #Page.pageIndex #Main #ContBox01 .divEnding p span {
            display: block;
            letter-spacing: -1.5px; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox01.introduce {
        padding-left: 15px;
        padding-right: 15px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox01.introduce h3:before {
          top: 3px;
          left: 10px;
          width: 33px;
          height: 35px;
          background: url("/common/img/contents/index_icon01.png") no-repeat scroll left center;
          background-size: 33px auto; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox02 {
        margin-top: 105px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox02 h3 {
          margin-bottom: 41px;
          padding-left: 1em;
          text-indent: -1em; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox02 h3:before {
            left: -21px;
            width: 35px;
            height: 35px;
            background: url("/common/img/contents/index_icon05.png") no-repeat scroll left center;
            background-size: 35px 35px;
            vertical-align: bottom; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox02 p {
          padding-left: 15px;
          padding-right: 15px;
          text-align: justify; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox02 p.dispsp.p_note {
            color: #666;
            font-size: 16px;
            line-height: 29px;
            letter-spacing: -0.4px;
            margin-bottom: 26px;
            text-align: center; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure {
          padding-left: 15px;
          padding-right: 15px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure img {
            width: 100%;
            height: auto; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure.graph01 {
          width: 100%;
          margin: 22px auto 8px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure.graph02 {
          width: 100%;
          margin: 23px auto 8px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox02 figure.graph03 {
          width: 100%;
          margin: 23px auto 9px; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox03 {
        margin-top: 89px;
        padding-left: 15px;
        padding-right: 15px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox03 h3 {
          margin-bottom: 40px;
          line-height: 40px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox03 h3:before {
            left: 10px;
            width: 35px;
            height: 30px;
            background: url("/common/img/contents/index_icon02.png") no-repeat scroll left center;
            background-size: 35px auto; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox03 p span {
          display: block;
          font-size: 16px;
          color: #666; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox03 ul {
          margin-top: 24px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox03 ul li {
            display: block;
            width: 100%;
            height: 60px; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox03 ul li:nth-of-type(1) {
              margin-bottom: 15px; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox03 ul li a {
              display: block;
              width: 100%;
              height: 100%;
              color: #fff;
              font-size: 22px;
              line-height: 60px;
              text-align: center;
              border-radius: 34px;
              background: #80ab50 url(../img/index_arrow02.png) no-repeat center right 28px;
              background-size: 13px auto; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox04 {
        margin-top: 78px;
        padding: 0 15px 88px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox04 h3 {
          margin-bottom: 50px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 h3:before {
            top: 10px;
            left: 10px;
            width: 47px;
            height: 53px;
            background: url("/common/img/contents/index_icon03.png") no-repeat scroll left center;
            background-size: 47px auto; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow {
          counter-reset: number;
          list-style: none;
          margin: 0;
          padding: 0; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li {
            width: 100%;
            border-radius: 8px;
            border: 3px solid #e9633a;
            background: #fff;
            font-size: 24px;
            color: #e9633a;
            position: relative;
            font-weight: 700;
            line-height: 33px;
            padding: 10px 9px 10px 44px; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li span.kifu_flow_span:after {
              position: relative;
              top: -3px;
              display: inline-block;
              width: 30px;
              height: 0px;
              font-size: 16px;
              margin-left: 12px;
              font-weight: 700; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(1) span.kifu_flow_span:after {
              content: "※1"; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(2) span.kifu_flow_span:after {
              content: "※2"; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(3) span.kifu_flow_span:after,
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(4) span.kifu_flow_span:after {
              display: none; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(5) span.kifu_flow_span:after {
              content: "※3"; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:nth-of-type(6) span.kifu_flow_span:after {
              content: "※4"; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:before {
              counter-increment: number;
              content: counter(number);
              display: inline-block;
              position: absolute;
              top: 15px;
              left: 11px;
              width: 23px;
              height: 23px;
              line-height: 23px;
              text-align: center;
              border-radius: 50%;
              background: #e9633a;
              font-size: 15px;
              font-weight: 600;
              color: #fff;
              font-family: "Open Sans", sans-serif;
              text-indent: 1px; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:after {
              content: "";
              width: 100%;
              height: 27px;
              display: inline-block;
              background: url(../img/contents/index_arrow01_sp.png) no-repeat center center;
              background-size: 27px auto;
              position: absolute;
              bottom: -29px;
              left: 0; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li + li {
              margin-top: 29px; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.kifu_flow li:last-of-type:after {
              display: none; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.ol_note {
          margin-top: 30px;
          padding-left: 15px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox04 ol.ol_note li {
            color: #666;
            font-size: 16px;
            line-height: 27px;
            list-style: none;
            padding-left: 1em;
            text-indent: -1.9em; }
      #Page.pageIndex #Main #ContBox01 #ContSubBox05 {
        padding: 93px 15px 58px;
        background-color: #fff; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox05 h3 {
          position: relative;
          width: 100%;
          margin: 0 auto;
          font-size: 24px;
          font-weight: 700;
          line-height: 34px;
          margin-bottom: 19px;
          letter-spacing: -2px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox05 h3:before {
            top: 3px;
            left: -5px;
            width: 27px;
            height: 23px;
            background: url("/common/img/contents/index_icon04.png") no-repeat scroll left center;
            background-size: 27px auto; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox05 h3 span.newsMore {
            display: none; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox05 p {
          text-align: center; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox05 p span.newsMore {
            position: relative;
            display: inline-block;
            color: #010101;
            font-size: 14px;
            letter-spacing: 0;
            margin-top: 23px; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox05 p span.newsMore:before {
              content: "";
              width: 8px;
              height: 34px;
              display: inline-block;
              background: url(../img/contents/arrow01.png) no-repeat center left;
              background-size: 8px auto;
              position: absolute;
              top: -3px;
              left: -15px; }
        #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl {
          width: 100%;
          margin: 0 auto;
          display: table;
          padding-top: 13px;
          padding-bottom: 11px;
          background: url(../img/contents/line_img.png) repeat-x left top;
          background-size: 3px auto; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl:last-child {
            background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
            background-repeat: repeat-x, repeat-x;
            background-position: left top, left bottom;
            background-size: 3px auto, 3px auto; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl dt {
            display: block;
            width: 100%;
            padding-bottom: 4px;
            vertical-align: top;
            font-family: 'Open Sans', sans-serif;
            color: #666;
            font-size: 16px;
            line-height: 30px; }
          #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl dd {
            display: block;
            color: #010101;
            font-size: 16px;
            line-height: 27px; }
            #Page.pageIndex #Main #ContBox01 #ContSubBox05 dl dd a:hover {
              text-decoration: underline; }
  /*
  遺贈寄付先について SP
  -------------------------------------*/
  #PageContributionlist.pageIndex #Main {
    width: 100%; }
    #PageContributionlist.pageIndex #Main #ContBox01 {
      margin-top: 48px;
      padding: 0; }
      #PageContributionlist.pageIndex #Main #ContBox01 div.greeting p {
        padding-left: 15px;
        padding-right: 15px; }
        #PageContributionlist.pageIndex #Main #ContBox01 div.greeting p.orange {
          color: #e9633a; }
      #PageContributionlist.pageIndex #Main #ContBox01 h3 {
        margin-bottom: 60px; }
        #PageContributionlist.pageIndex #Main #ContBox01 h3:before {
          display: inline-block;
          content: "";
          width: 34px;
          height: 47px;
          position: relative;
          top: 3px;
          margin-right: 15px; }
      #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 {
        margin-top: 80px;
        padding-left: 15px;
        padding-right: 15px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 h3 {
          margin-bottom: 47px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 h3:before {
            display: inline-block;
            content: "";
            background: url("/common/img/contents/kifusaki_icon01.png") no-repeat scroll left center;
            background-size: 25px auto;
            position: relative;
            top: 9px;
            margin-right: 5px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divLeft {
          width: 100%;
          margin-bottom: 18px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divLeft figure {
            width: 100%; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divLeft figure img {
              width: 100%; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight {
          width: 100%; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight p:nth-of-type(1) {
            color: #000;
            font-size: 24px;
            font-weight: 700;
            line-height: 35px;
            margin-bottom: 8px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight ul {
            margin-bottom: 8px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight ul li {
              background: url("/common/img/contents/kifusaki_icon06.png") no-repeat scroll left 1px;
              background-size: 23px auto;
              padding-left: 36px; }
              #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox01 .divRight ul li + li {
                margin-top: 5px; }
      #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 {
        margin-top: 89px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 div.greeting {
          margin-bottom: 22px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h3 {
          margin-bottom: 42px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h3:before {
            content: "";
            display: inline-block;
            width: 42px;
            height: 34px;
            background: url("/common/img/contents/kifusaki_icon02.png") no-repeat scroll left center;
            background-size: 42px auto;
            position: relative;
            top: 3px;
            margin-right: 15px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .parentAccordion.minus h4 {
          border-bottom: 1px solid #e7e7e7;
          background: #fff url("/common/img/contents/sp_icon_open.png") no-repeat scroll right 15px center;
          background-size: 23px auto; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 div.accordion {
          display: none; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4 {
          color: #714721;
          background-color: #fff;
          width: 100%;
          height: 65px;
          font-size: 24px;
          font-weight: 700;
          line-height: 65px;
          border-top: 1px solid #e7e7e7;
          background: #fff url("/common/img/contents/sp_icon_close.png") no-repeat scroll right 15px center;
          background-size: 23px auto; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4:before {
            content: "";
            display: inline-block;
            position: relative;
            left: 15px;
            top: 3px;
            margin-right: 26px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4#contributionlist_01:before {
            width: 24px;
            height: 27px;
            background: url("/common/img/contents/kifusaki_icon03_sp.png") no-repeat scroll left center;
            background-size: 24px auto; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4#contributionlist_02:before {
            width: 23px;
            height: 27px;
            background: url("/common/img/contents/kifusaki_icon04_sp.png") no-repeat scroll left center;
            background-size: 23px auto; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 h4#contributionlist_03:before {
            width: 29px;
            height: 23px;
            background: url("/common/img/contents/kifusaki_icon05_sp.png") no-repeat scroll left center;
            background-size: 29px auto;
            top: 5px;
            margin-right: 20px; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner {
          margin-top: 39px;
          margin-bottom: 60px;
          padding-left: 15px;
          padding-right: 15px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner figure img {
            width: 100%; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div {
            width: 100%; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div p,
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div dl {
              font-size: 16px;
              line-height: 27px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div a {
              color: #80ab50; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div {
              margin-top: 8px; }
              #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div dl {
                margin-bottom: 8px; }
                #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div dl dt {
                  font-weight: 700; }
                  #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div dl dt span.orange {
                    color: #e9633a; }
                #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner > div div dl dd {
                  margin-left: 1em; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner p.another_link {
            margin: 20px 0 15px;
            font-size: 24px;
            line-height: 35px;
            font-weight: 700;
            padding-left: 1.2em;
            background: url("/common/img/contents/icon_window.png") no-repeat scroll left 5px;
            background-size: 22px auto; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner p.another_link span {
              display: block;
              font-size: 18px;
              line-height: 28px; }
            #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 .accordionInner p.another_link a {
              color: #80ab50;
              text-decoration: underline; }
        #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 #contributionlist_04 {
          border-bottom: 1px solid #e7e7e7;
          padding-left: 15px; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 #contributionlist_04:before {
            display: none; }
          #PageContributionlist.pageIndex #Main #ContBox01 #ContSubBox02 #contributionlist_04 + .accordion p.another_link {
            background: none;
            padding-left: 0; }
  /*
  トピックス共通 SP
  -------------------------------------*/
  #PageTopics #Main {
    width: 100%; }
    #PageTopics #Main #ContBox01 {
      margin-top: 53px;
      margin-bottom: 100px; }
      #PageTopics #Main #ContBox01 dl {
        width: 100%;
        padding: 18px 0;
        background: url(../img/contents/line_img.png) repeat-x left top;
        background-size: 3px auto; }
        #PageTopics #Main #ContBox01 dl:last-of-type {
          background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
          background-repeat: repeat-x, repeat-x;
          background-position: left top, left bottom;
          background-size: 3px auto, 3px auto; }
        #PageTopics #Main #ContBox01 dl dt {
          overflow: hidden;
          width: 100%;
          font-size: 18px;
          line-height: 29px; }
          #PageTopics #Main #ContBox01 dl dt a:link,
          #PageTopics #Main #ContBox01 dl dt a:active,
          #PageTopics #Main #ContBox01 dl dt a:visited {
            color: #010101; }
        #PageTopics #Main #ContBox01 dl dd {
          display: inline-block;
          vertical-align: middle; }
        #PageTopics #Main #ContBox01 dl dd.date {
          width: 90px;
          padding-right: 15px;
          font-family: "Open Sans", sans-serif;
          color: #666;
          font-size: 16px;
          line-height: 27px; }
      #PageTopics #Main #ContBox01 .category {
        height: 20px;
        margin-left: 3px;
        padding-right: 8px;
        padding-left: 6px;
        border: 1px solid #e9633a;
        border-radius: 3px;
        background-color: #fff;
        color: #e9633a;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -1px; }
  /*
  トピックス 見出し共通 SP
  -------------------------------------*/
  #PageTopics #Main #ContBox01 h3 {
    font-size: 34px;
    text-align: left;
    margin-bottom: 21px;
    line-height: 40px; }
  /*
  月別アーカイブ SP
  -------------------------------------*/
  #PageTopics.pageMonthly #Main #ContBox01 h3 {
    letter-spacing: 2px; }
  /*
  記事ページ SP
  -------------------------------------*/
  #PageTopics.pageEntry #Main #ContBox01 h3 {
    margin-top: 4px;
    margin-bottom: 19px; }
  #PageTopics.pageEntry #Main #ContBox01 span.date {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #666;
    line-height: 34px; }
  #PageTopics.pageEntry #Main #ContBox01 span.category {
    position: relative;
    top: -2px;
    left: 12px; }
  #PageTopics.pageEntry #Main #ContBox01 .entryBox {
    padding-top: 26px;
    padding-bottom: 59px;
    border-top: 3px solid #e2ded4;
    border-bottom: 1px solid #e2ded4; }
  /*
  会社案内 SP
  -------------------------------------*/
  #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 {
    margin-top: 96px;
    margin-bottom: 80px; }
    #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 h3 {
      margin-bottom: 48px; }
    #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft {
      width: 100%; }
      #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft #GoogleMap {
        width: 100%;
        height: 360px;
        margin-bottom: 5px;
        border: 1px solid #d9d9d9; }
      #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft p {
        text-align: right; }
        #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft p a {
          color: #86c441;
          font-size: 14px; }
          #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft p a:before {
            display: inline-block;
            content: "";
            width: 23px;
            height: 17px;
            background: url("/common/img/contents/kaisya_icon01.png") no-repeat scroll left center;
            background-size: 17px auto;
            position: relative;
            top: 3px; }
          #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divLeft p a:hover {
            text-decoration: underline; }
    #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight {
      width: 100%;
      margin-top: 32px; }
      #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl {
        padding: 14px 0 15px;
        font-size: 18px;
        line-height: 29px;
        overflow: hidden;
        background: url(../img/contents/line_img.png) repeat-x left top;
        background-size: 3px auto; }
        #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl:last-of-type {
          background: url(../img/contents/line_img.png), url(../img/contents/line_img.png);
          background-repeat: repeat-x, repeat-x;
          background-position: left top, left bottom;
          background-size: 3px auto, 3px auto; }
        #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl dt {
          float: left;
          width: 34%;
          font-weight: 700; }
        #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl dd {
          float: left;
          width: 66%; }
          #PageCompany.pageIndex #Main #ContBox01 #ContSubBox02 .divRight dl dd a {
            text-decoration: underline; } }
